import React from "react";
import { render } from "react-dom";
import { Router } from "@reach/router";
import Home from "./Home";
import Results from "./Results";
import Search from "./Search";
import Header from "./Header";
import Footer from "./Footer";

const app = () => {
  return (
    <div className="main-container">
      <Header />
      <Search />
      <Router>
        <Home path="/" />
        <Results path="/:id" />
      </Router>
      <Footer />
    </div>
  );
};

render(React.createElement(app), document.getElementById("root"));
