import React from "react";

const Footer = () => {
  return (
    <footer className="footer-container">
      <div className="footer-content">
        <p className="footer-text">
          This dumb project was built with React. Data courtesy of&nbsp;
          <a className="footer-link" href="https://finnhub.io/">
            Finnhub
          </a>
          .&nbsp;Enjoy!
        </p>
        <p className="footer-text">
          Here&apos;s a{" "}
          <a className="footer-link" href="https://join.robinhood.com/rosss262">
            Robinhood referal link
          </a>
          , if you want to buy some&nbsp;stonks.
        </p>
        <p className="footer-text">
          Here&apos;s my{" "}
          <a className="footer-link" href="https://linkedin.com/in/raswanson">
            linkedin
          </a>
          , if you want to&nbsp;connect.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
