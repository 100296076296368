import React, { useState } from "react";
import { token } from "./private/keys.json";

const Results = ({ id }) => {
  const url = `https://finnhub.io/api/v1/quote?symbol=${id}&token=${token}`;

  const [openingPrice, setOpeningPrice] = useState(-999);
  const [currentPrice, setCurrentPrice] = useState(-999);
  const [stonks, setStonks] = useState([]);

  const [loaded, setLoaded] = useState(false);
  const [image, setImage] = useState([]);
  const [alt, setAlt] = useState([]);
  const [description, setDescription] = useState([]);
  const [gainClass, setGainClass] = useState([]);
  const [emoji, setEmoji] = useState();

  const [checkID, setCheckID] = useState();

  async function requestPrices() {
    const promise = fetch(url);

    const priceArray = await promise
      .then(function (response) {
        setLoaded(false);
        const processingPromise = response.json();
        return processingPromise;
      })
      .then(function (processedResponse) {
        let arr = [processedResponse.c, processedResponse.o];
        return arr;
      })
      .then((arr) => {
        setCurrentPrice(arr[0]);
        setOpeningPrice(arr[1]);
        return arr[0] > arr[1];
      })
      .then((isStonks) => {
        setStonks(isStonks);
        setImage(
          isStonks
            ? "https://i.ytimg.com/vi/if-2M3K1tqk/maxresdefault.jpg"
            : "https://i.ytimg.com/vi/9G116gD3iyI/maxresdefault.jpg"
        );
        setAlt(isStonks ? "stonks!" : "not stonks");
        setDescription(`${id} is ${isStonks ? "stonks" : `not stonks`}`);
        setGainClass(isStonks ? "gain" : "loss", []);
        setEmoji(isStonks ? `📈` : `📉`);
        setLoaded(true, []);
      });
  }

  if (checkID !== id) {
    requestPrices();
    setCheckID(id, []);
  }
  return loaded ? (
    <div className="stonk-container">
      <img className="stonk-image" src={image} alt={alt} />
      <h2 className="is-stonks">{description}</h2>
      <h3 className={gainClass}>
        {emoji} ${(currentPrice - openingPrice).toFixed(2)}
        {emoji}
      </h3>
      <div className="price-container">
        <p className="price-text">Current Price: ${currentPrice.toFixed(2)}</p>
        <p className="price-text">Opening Price: ${openingPrice.toFixed(2)}</p>
      </div>
    </div>
  ) : (
    <div className="loading">
      <p className="price-text">Loading ...</p>
    </div>
  );
};

export default Results;
