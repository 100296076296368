import React from "react";
import { Link } from "@reach/router";

const Header = () => {
  return (
    <header className="header-container">
      <Link className="home-link" to="/">
        <h1 className="stonks">STONKS</h1>
      </Link>
      <Link className="home-link" to="/">
        <h1 className="not-stonks">NOT STONKS</h1>
      </Link>
    </header>
  );
};

export default Header;
