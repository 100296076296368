import React from "react";

const Home = () => {
  return (
    <div className="home-container">
      <h2 className="home-subhead">How are your stonks doing?</h2>
      <p className="home-text">Search by company name or stonk&nbsp;symbol</p>
      <p className="home-text">
        We&apos;ll tell you if they&apos;re stonks or&nbsp;not
      </p>
    </div>
  );
};

export default Home;
