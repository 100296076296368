const ValidateStonk = (search, symbols, dict) => {
  if (symbols.includes(search)) {
    return search;
  } else if (dict[search]) {
    return dict[search];
  }
  return null;
};

export default ValidateStonk;
