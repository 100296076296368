import React, { useState } from "react";
import { navigate } from "@reach/router";
import { token } from "./private/keys.json";
import ValidateStonk from "./ValidateSearch";

const Search = () => {
  const [symbol, setSymbol] = useState([]);
  const url = `https://finnhub.io/api/v1/stock/symbol?exchange=US&token=${token}`;

  const symbolArray = [];
  const nameArray = [];
  const stonksArray = [];
  const dict = {};

  const [symbolsRequested, setSymbolsRequested] = useState(false);
  const [symbols, setSymbols] = useState([]);
  const [stonks, setStonks] = useState([]);
  const [stonkDict, setStonkDict] = useState();
  const [autofillSymbols, setAutofillSymbols] = useState([]);

  async function requestStonkSymbols() {
    const promise = fetch(url);

    const symbolResponse = await promise
      .then(function (response) {
        const processingPromise = response.json();
        return processingPromise;
      })
      .then(function (processedResponse) {
        return processedResponse;
      });

    symbolResponse.map((s) => {
      symbolArray.push(s.symbol);
      nameArray.push(s.description);
      stonksArray.push(s.symbol);
      stonksArray.push(s.description);
      dict[s.description] = s.symbol;
    });

    setSymbols(symbolArray);
    setStonks(stonksArray);
    setStonkDict(dict);
  }

  if (!symbolsRequested) {
    requestStonkSymbols();
    setSymbolsRequested(true);
  }

  return (
    <div className="search-container">
      <form
        className="search-form"
        typeof="POST"
        onSubmit={(e) => {
          e.preventDefault();
          let searchID = ValidateStonk(symbol, symbols, stonkDict);
          setAutofillSymbols([]);
          if (searchID) {
            navigate(`/${searchID}`);
          }
        }}
      >
        <label className="stonk-search-label" htmlFor="symbol">
          <input
            className="stonk-search"
            id="symbol"
            value={symbol}
            placeholder="Search for a stonk"
            onChange={(e) => {
              setSymbol(e.target.value);
              if (e.target.value.length > 0) {
                setAutofillSymbols(
                  stonks.filter((sym) =>
                    sym.toLowerCase().includes(e.target.value.toLowerCase())
                  )
                );
              } else {
                setAutofillSymbols([]);
              }
            }}
            type="text"
          />
        </label>

        <button id="search-button">Search &rarr;</button>
      </form>
      <div className="autofill-container">
        {autofillSymbols.map((sym, index) => {
          if (index < 20)
            return (
              <input
                className="autofill-result"
                type="Button"
                key={index}
                onClick={(e) => {
                  setSymbol(e.target.value);
                  setAutofillSymbols([]);
                }}
                defaultValue={sym}
              />
            );
        })}
      </div>
    </div>
  );
};

export default Search;
